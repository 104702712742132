/**
 * @file Manages styles for Header atom.
 * @author kkumari
 */
import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
import fontColor from '../../../constants/globalConstants';
export default css(["padding:var(--sc-spacing-7,40px) var(--sc-spacing-4,20px);background-color:var(--sc-form--bg-color,", ");label{color:", ";}#ohhoneyname,label[for='ohhoneyname']{opacity:0;position:absolute;top:0;left:0;height:0;width:0;z-index:-1;color:", ";}.form-heading,.success-message,.error-message{text-align:center;width:100%;margin-bottom:var(--sc-spacing-5,30px);@media (min-width:", "){margin-bottom:var(--sc-spacing-12,80px);}}input{background-color:var(--sc-form--bg-color,", ");border-radius:0;border-top:none;border-right:none;border-left:none;border-bottom:1px solid;border-color:", ";width:100%;height:32px;&:placeholder-shown{padding-top:1px;padding-bottom:1px;}&:not(:placeholder-shown),&:focus{padding-top:var(--sc-spacing-5,25px);padding-bottom:var(--sc-spacing-5,25px);}}.btn-success{background:#ececec;}textarea{background-color:var(--sc-form--bg-color,", ");width:100%;height:200px;border-color:", ";margin-top:var(--sc-spacing-3,15px);border:1px solid;}button[type='submit']{margin-top:var(--sc-spacing-5,30px);@media (min-width:", "){margin-top:var(--sc-spacing-9,60px);}}.error-message{color:var(--sc-form--color-error,", ");}.user-name-item{text-indent:100%;white-space:nowrap;overflow:hidden;height:0;}@media (min-width:", "){padding:var(--sc-spacing-7,40px) var(--sc-spacing-16,115px);}@media (min-width:", "){padding:var(--sc-spacing-12,80px) 258px var(--sc-spacing-9,56px);}"], function (props) {
  var _props$theme, _props$theme$form;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : (_props$theme$form = _props$theme.form) === null || _props$theme$form === void 0 ? void 0 : _props$theme$form.bgColor;
}, function (props) {
  var _props$theme2, _props$theme2$form, _props$theme3, _props$theme3$form;
  return props && props.contactUsData && props.contactUsData.fontColor === fontColor.light ? "var(--sc-form--color-text-light, ".concat((_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : (_props$theme2$form = _props$theme2.form) === null || _props$theme2$form === void 0 ? void 0 : _props$theme2$form.textColorLight, ")") : "var(--sc-form--color-text-dark, ".concat((_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : (_props$theme3$form = _props$theme3.form) === null || _props$theme3$form === void 0 ? void 0 : _props$theme3$form.textColorDark, ")");
}, function (props) {
  var _props$theme4, _props$theme4$form, _props$theme5, _props$theme5$form;
  return props && props.contactUsData && props.contactUsData.fontColor === fontColor.light ? "var(--sc-form--color-text-light, ".concat((_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : (_props$theme4$form = _props$theme4.form) === null || _props$theme4$form === void 0 ? void 0 : _props$theme4$form.textColorLight, ")") : "var(--sc-form--color-text-dark, ".concat((_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : (_props$theme5$form = _props$theme5.form) === null || _props$theme5$form === void 0 ? void 0 : _props$theme5$form.textColorDark, ")");
}, breakPoints.desktop, function (props) {
  var _props$theme6, _props$theme6$form;
  return (_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : (_props$theme6$form = _props$theme6.form) === null || _props$theme6$form === void 0 ? void 0 : _props$theme6$form.bgColor;
}, function (props) {
  var _props$theme7, _props$theme7$form, _props$theme8, _props$theme8$form;
  return props && props.contactUsData && props.contactUsData.fontColor === fontColor.light ? "var(--sc-form--color-text-light, ".concat((_props$theme7 = props.theme) === null || _props$theme7 === void 0 ? void 0 : (_props$theme7$form = _props$theme7.form) === null || _props$theme7$form === void 0 ? void 0 : _props$theme7$form.textColorLight, ")") : "var(--sc-form--color-text-dark, ".concat((_props$theme8 = props.theme) === null || _props$theme8 === void 0 ? void 0 : (_props$theme8$form = _props$theme8.form) === null || _props$theme8$form === void 0 ? void 0 : _props$theme8$form.textColorDark, ")");
}, function (props) {
  var _props$theme9, _props$theme9$form;
  return (_props$theme9 = props.theme) === null || _props$theme9 === void 0 ? void 0 : (_props$theme9$form = _props$theme9.form) === null || _props$theme9$form === void 0 ? void 0 : _props$theme9$form.bgColor;
}, function (props) {
  var _props$theme10, _props$theme10$form, _props$theme11, _props$theme11$form;
  return props && props.contactUsData && props.contactUsData.fontColor === fontColor.light ? "var(--sc-form--color-text-light, ".concat((_props$theme10 = props.theme) === null || _props$theme10 === void 0 ? void 0 : (_props$theme10$form = _props$theme10.form) === null || _props$theme10$form === void 0 ? void 0 : _props$theme10$form.textColorLight, ")") : "var(--sc-form--color-text-dark, ".concat((_props$theme11 = props.theme) === null || _props$theme11 === void 0 ? void 0 : (_props$theme11$form = _props$theme11.form) === null || _props$theme11$form === void 0 ? void 0 : _props$theme11$form.textColorDark, ")");
}, breakPoints.desktop, function (props) {
  var _props$theme12, _props$theme12$form;
  return (_props$theme12 = props.theme) === null || _props$theme12 === void 0 ? void 0 : (_props$theme12$form = _props$theme12.form) === null || _props$theme12$form === void 0 ? void 0 : _props$theme12$form.errorColor;
}, breakPoints.tabletP, breakPoints.desktop);